import { Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";
import heyTacoFull from "../assets/heyTacoFull.png";
import heyTacoLogo from "../assets/heyTacoLogo.png";
type Props = {
  handleAdvance: () => void;
};

const Home = ({ handleAdvance }: Props) => {
  const [email, setEmail] = React.useState("");
  const [validationMessage, setValidationMessage] = React.useState("");
  return (
    <Flex justifyContent={"center"}>
      <Image
        position="absolute"
        left="0"
        width="79px"
        margin="20px"
        src={heyTacoLogo}
        alt="Hey Taco"
      />

      <Flex flexDirection={"column"} maxWidth="750px">
        <Box textAlign={"center"}>
          <Text fontSize="60px" color="#050F5F" fontWeight="600">
            Employee Appreciation Holiday Gift Selection
          </Text>
        </Box>
        <Flex
          flexDirection={"row"}
          justifyContent="center"
          alignItems={"center"}
          margin="24px 0"
        >
          <Text fontWeight="600" fontSize="30px" margin="0 24px">
            Powered by
          </Text>
          <Image width="204px" src={heyTacoFull} alt="Hey Taco" />
        </Flex>
        <Box>
          <Text fontSize="30px" color="#6B7081" fontWeight="400">
            Instructions:
            <br />
            1. Enter your email address in the provided field.
            <br />
            2. Follow the prompts and select your preferred gift card. Options
            include $100 for AMEX, Starbucks, Target, and more.
            <br />
            3. Click the "Submit" button to complete your request.
            <br />
            4. You will receive an email confirmation once your request has been
            processed.
            <br />
            5. If you have any issues or questions, please contact your HR
            department for assistance.
            <br />
            <br />
          </Text>
        </Box>
        <Box>
          <Text size={"lg"} marginBottom="12px">
            {validationMessage || "Enter your email:"}
          </Text>
          <Input
            isInvalid={validationMessage !== ""}
            placeholder="example@heytaco.com"
            size="lg"
            backgroundColor="white"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Box textAlign={"center"} margin="24px">
            <Button
              cursor="pointer !important"
              colorScheme="yellow"
              color={"white"}
              size={"lg"}
              onClick={() => {
                if (
                  String(email)
                    .toLowerCase()
                    .match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )
                ) {
                  LogRocket.identify(email);
                  handleAdvance();
                } else {
                  setValidationMessage("Please enter a valid email address.");
                }
              }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Home;
