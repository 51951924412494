import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import React from "react";
import heyTacoFull from "../assets/heyTacoFull.png";
import heyTacoLogo from "../assets/heyTacoLogo.png";

type Props = {};

const Thanks = (props: Props) => {
  const [isHover, setIsHover] = React.useState(false);
  return (
    <Flex justifyContent={"center"}>
      <Image
        position="absolute"
        left="0"
        width="79px"
        margin="20px"
        src={heyTacoLogo}
        alt="Hey Taco"
      />

      <Flex flexDirection={"column"} maxWidth="750px">
        <Box textAlign={"center"}>
          <Text fontSize="60px" color="#050F5F" fontWeight="600">
            Employee Appreciation Holiday Gift Selection
          </Text>
        </Box>
        <Flex
          flexDirection={"row"}
          justifyContent="center"
          alignItems={"center"}
          margin="24px 0"
        >
          <Text fontWeight="600" fontSize="30px" margin="0 24px">
            Powered by
          </Text>
          <Image width="204px" src={heyTacoFull} alt="Hey Taco" />
        </Flex>
        <Box textAlign={"center"}>
          <Text fontSize="30px" color="#6B7081" fontWeight="400">
            Oops, looks like you accidentally opted out of your holiday gift.
            Don't worry, we won't hold it against you. Just kidding, we totally
            will. But seriously, if you meant to choose a gift and just clicked
            the wrong button, it's not too late to change your mind. Just click
            the 'Change My Mind' button below and you can select your gift card
            of choice. Or, if you're sure you don't want a gift card, that's
            totally fine too. We just wanted to make sure you didn't miss out on
            something that was meant for you. Happy holidays!
            <br />
            <br />
          </Text>
        </Box>

        <Box textAlign={"center"} margin="24px">
          <Button
            cursor="pointer !important"
            colorScheme="facebook"
            color={"white"}
            size={"lg"}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            {isHover ? "Get back to work!" : "Change My Mind"}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Thanks;
