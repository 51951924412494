import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import React from "react";
import heyTacoFull from "../assets/heyTacoFull.png";
import heyTacoLogo from "../assets/heyTacoLogo.png";

type Props = {
  handleAdvance: () => void;
};

enum Position {
  Start = "flex-start",
  Center = "center",
  End = "flex-end",
}

const Selection = ({ handleAdvance }: Props) => {
  const [vertical, setVertical] = React.useState(Position.Center);
  const [horizontal, setHorizontal] = React.useState(Position.Center);
  const [counter, setCounter] = React.useState(0);
  const [isClicked, setIsClicked] = React.useState(false);

  const handleHover = () => {
    if (counter < 5) {
      const verticalOptions = Object.values(Position).filter(
        (position) => position !== vertical
      );
      const horizontalOptions = Object.values(Position).filter(
        (position) => position !== horizontal
      );
      const newVertical =
        verticalOptions[Math.floor(Math.random() * verticalOptions.length)];
      const newHorizontal =
        horizontalOptions[Math.floor(Math.random() * horizontalOptions.length)];
      setVertical(newVertical);
      setHorizontal(newHorizontal);
      setCounter((counter) => counter + 1);
    }
  };
  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => {
      handleAdvance();
    }, 1000);
  };
  return (
    <Flex justifyContent={"center"}>
      <Flex flexDirection={"column"}>
        <Image
          position="absolute"
          left="0"
          width="79px"
          margin="20px"
          src={heyTacoLogo}
          alt="Hey Taco"
        />
        <Flex flexDirection={"column"} maxWidth="750px">
          <Box textAlign={"center"}>
            <Text fontSize="60px" color="#050F5F" fontWeight="600">
              Employee Appreciation Holiday Gift Selection
            </Text>
          </Box>
          <Flex
            flexDirection={"row"}
            justifyContent="center"
            alignItems={"center"}
            margin="24px 0"
          >
            <Text fontWeight="600" fontSize="30px" margin="0 24px">
              Powered by
            </Text>
            <Image width="204px" src={heyTacoFull} alt="Hey Taco" />
          </Flex>
          <Box textAlign={"center"}>
            <Text fontSize="30px" color="#6B7081" fontWeight="400">
              Would you like to redeem your holiday gift now?
            </Text>
          </Box>
          <Flex flex="1" padding={"0 20px"}>
            <Flex
              flex="1"
              justifyContent={"center"}
              alignItems={"center"}
              height="200px"
            >
              <Button
                cursor="pointer !important"
                size={"lg"}
                onClick={handleAdvance}
                colorScheme={isClicked ? "whatsapp" : "orange"}
                color="white"
              >
                {isClicked ? "Yes" : "No"}
              </Button>
            </Flex>
            <Flex flex="1" justifyContent={horizontal} alignItems={vertical}>
              <Button
                cursor="pointer !important"
                size={"lg"}
                onMouseEnter={handleHover}
                onClick={handleClick}
                colorScheme={isClicked ? "orange" : "whatsapp"}
                color="white"
              >
                {isClicked ? "No" : "Yes"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Selection;
