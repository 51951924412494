import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Home from "./components/Home";
import Selection from "./components/Selection";
import Thanks from "./components/Thanks";
import LogRocket from "logrocket";
LogRocket.init("t7b4zc/heytaco-empioyee");

enum Views {
  home = "home",
  selection = "selection",
  thanks = "thanks",
}

function App() {
  const [currentView, setCurrentView] = React.useState("home");
  const handleAdvance = () => {
    if (currentView === Views.home) {
      setCurrentView(Views.selection);
    } else if (currentView === Views.selection) {
      setCurrentView(Views.thanks);
    }
  };
  return (
    <ChakraProvider>
      {currentView === Views.home && <Home handleAdvance={handleAdvance} />}
      {currentView === Views.selection && (
        <Selection handleAdvance={handleAdvance} />
      )}
      {currentView === Views.thanks && <Thanks />}
    </ChakraProvider>
  );
}

export default App;
