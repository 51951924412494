import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    {window.innerWidth < 900 || navigator.maxTouchPoints > 0 ? (
      <>
        <div>Please open this site in your computer</div>
        <br />
        <div>Error 56248</div>
        <div>No company found</div>
        <br />
        <div>Error 24581</div>
        <div>No touchscreen allowed</div>
      </>
    ) : (
      <App />
    )}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
